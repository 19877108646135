:root {
  --theme_primary_color: #07081b;
  --theme_background_color: #17193a;
  --theme_secondary_color: #12132e;
  --btn_and_active_color: #d736d1;
  --title_font_color: #fff;
  --con_title_and_input_color: #202242;
  --con_font_color: #fff;
  --font_primary_color: #fff;
  --font_secondary_color: #646572;
}

//css
$theme-background-primary: var(--theme_primary_color);
$theme-background-secondary: var(--theme_background_color);
$theme-background-third: var(--theme_secondary_color);
$theme-btn: var(--btn_and_active_color);
$theme-title-font: var(--title_font_color);
$theme-title-background: var(--con_title_and_input_color);
$theme-conversation-font: var(--con_font_color);
$theme-font-primary: var(--font_primary_color);
$theme-font-secondary: var(--font_secondary_color);
$theme-role-glass-bg: var(--theme_secondary_color);
$theme-role-edit-glass-bg: var(--theme_secondary_color);

//jsx
:export {
  theme-background-primary: $theme-background-primary;
  theme-background-secondary: $theme-background-secondary;
  theme-background-third: $theme-background-third;
  theme-btn: $theme-btn;
  theme-title-font: $theme-title-font;
  theme-title-background: $theme-title-background;
  theme-conversation-font: $theme-conversation-font;
  theme-font-primary: $theme-font-primary;
  theme-font-secondary: $theme-font-secondary;
  theme-role-glass-bg: $theme-role-glass-bg;
  theme-role-edit-glass-bg: $theme-role-edit-glass-bg;
}
