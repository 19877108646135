@use "../../../MainStyle";

.table-input {
  margin-top: 5px;
  padding: 5px;
  width: 100% !important;
  background: transparent !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: MainStyle.$theme-font-primary !important;
  border: none !important;
}
.history_title_clm .editable-cell-value-wrap input {
  background: transparent !important;
}
.history-action-icons {
  font-size: 20px;
  cursor: pointer;
}
.his-button {
  background: #3a3d63;
  border-radius: 4px;
  color: MainStyle.$theme-font-primary;
  border: none;
}

.his-delete {
  background: #fb4b4b;
  border-radius: 4px;
  color: MainStyle.$theme-font-primary;
  border: none;
}

.his-del {
  color: #ff3e3e;
}

.his-link {
  color: #45d722;
}

.his-delete-text {
  padding-left: 5px;
}
.his-delete-text .ant-btn-default {
  background: #fb4b4b !important;
}
.history_btn {
  width: 40px;
  padding: 0px;
}

.his-delete:hover {
  background: #fb4b4b !important;
  color: MainStyle.$theme-font-primary !important;
}

.costimize-bootstrap-table .ant-select-selector {
  display: none !important;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
  color: MainStyle.$theme-font-primary !important;
}

.ant-pagination-item-ellipsis {
  color: MainStyle.$theme-font-primary !important;
}

/* .history-search {
  display: flex !important;
  justify-content: right !important;
  margin: 1rem;
}

.history-search.ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
} */

.ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
}

.history-delete-modal .ant-btn-default {
  background: #3a3d63 !important;
  border: none !important;
  color: MainStyle.$theme-font-primary !important;
}
/* .ant-btn-default {
    background: #3A3D63 !important;
    border: none !important;
    color: MainStyle.$theme-font-primary !important;

} */

.ant-btn-primary {
  background: MainStyle.$theme-btn !important;
  color: MainStyle.$theme-font-primary;
}
.history-delete-modal .ant-popconfirm .ant-btn-default {
  background: #3a3d63 !important;
  border: none !important;
  color: MainStyle.$theme-font-primary !important;
}

.history-delete-modal .ant-btn-primary {
  color: MainStyle.$theme-font-primary !important;
  background-color: MainStyle.$theme-btn !important;
  border: none !important;
}

.edit-button .ant-btn-default {
  background: #3a3d63 !important;
  border: none !important;
  color: MainStyle.$theme-font-primary !important;
}

.edit-button .ant-btn-primary {
  color: MainStyle.$theme-font-primary !important;
  background-color: MainStyle.$theme-btn !important;
  border: none !important;
}
