@use "../../../MainStyle";

.pro-body {
  background-color: MainStyle.$theme-background-secondary !important;
  color: MainStyle.$theme-font-primary !important;
  margin-top: 4rem;
  height: 100%;
  min-height: 100vh;
}

.pro-sub-body {
  color: MainStyle.$theme-font-primary !important;
  margin: 1rem 4rem;
  max-height: 100% !important;
}

.token-cal {
  background-color: transparent !important;
}

.prompts-history-delete-icon {
  font-size: 17px;
  color: MainStyle.$theme-font-primary;
  margin: -0.3rem -0.5rem;
}

.pro-sub-body .ant-picker .ant-picker-clear {
  color: MainStyle.$theme-font-primary;
  background-color: MainStyle.$theme-background-third;
}

.pro-sub-body .ant-picker .ant-picker-input > input,
.pro-sub-body .ant-picker .ant-picker-input > input::placeholder {
  color: MainStyle.$theme-font-primary;
}

.token-card:hover {
  color: MainStyle.$theme-font-primary !important;
  border-color: #848484 !important;
}

.aivolvex-dashboard-outline-setup {
  background: linear-gradient(to top, transparent, #848484, #848484) !important;
  border-radius: 10px;
  padding: 0.6px;
  display: inline-block;
  height: 80%;
}

.body-title {
  color: MainStyle.$theme-title-font;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.token-title-mobile {
  display: flex;
  justify-content: start;
}

.token-cal .ant-picker {
  background-color: MainStyle.$theme-background-third !important;
  color: MainStyle.$theme-font-primary !important;
  /* width: 100%; */
}

.token-cal .ant-picker:hover {
  background-color: MainStyle.$theme-background-third !important;
  color: MainStyle.$theme-font-primary !important;
  border-color: #848484 !important;
}

.token-cal .ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
  color: MainStyle.$theme-font-primary !important;
}

.token-cal .ant-picker-panel {
  background-color: #2a2c56 !important;
  color: MainStyle.$theme-font-primary !important;
}

.token-cal .ant-picker-dropdown .ant-picker-cell-in-view {
  color: MainStyle.$theme-font-primary !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view {
  color: MainStyle.$theme-font-primary !important;
}

.ant-picker-dropdown
  .ant-picker-month-panel
  .ant-picker-cell-disabled
  .ant-picker-cell-inner {
  color: MainStyle.$theme-font-secondary !important;
}

.token-card-main {
  border-radius: 10px;
  border: none;
  background: MainStyle.$theme-background-third !important;
  backdrop-filter: blur(5.627785682678223px) !important;
  color: MainStyle.$theme-font-primary !important;
}

.agent-icon-button {
  background-color: MainStyle.$theme-btn !important;
  border: none;
}

.agent-icon {
  color: MainStyle.$theme-font-primary;
}

.agent-button {
  display: flex;
  justify-content: end;
}

.agent-text {
  display: flex !important;
  justify-content: center !important;
  padding: 1rem !important;
  font-size: 18px !important;
  width: 100%;
}

.token-agent {
  width: 100% !important;
}

.token-card-main.ant-card-body {
  background: MainStyle.$theme-background-secondary !important;
}

.token-card-main.ant-card .ant-card-body {
  padding: 9px !important;
}

.token-card {
  border-radius: 10px;
  border: none;
  border: 1.126px solid rgba(255, 255, 255, 0.61);
  background: transparent;
  backdrop-filter: blur(5.627785682678223px);
  color: MainStyle.$theme-font-primary !important;
}

.token-border {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.create-token {
  min-height: 90vh !important;
}

.create-token-body {
  display: flex;
  justify-content: center;
}

.sub-header {
  padding-top: 10px;
}

.create-text {
  color: MainStyle.$theme-font-primary;
  font-size: 18px;
}

.agent-header-button {
  width: 100%;
}

.agent-header-button-text-two {
  display: flex;
  justify-content: start;
}

.back-icon {
  font-size: 24px;
  color: MainStyle.$theme-title-font;
}

.delete-icon {
  color: MainStyle.$theme-btn !important;
}

.delete-icon-button {
  background: #fb4b4b !important;
}

.back-icon-sub {
  display: flex;
  align-items: center;
}

.agent-header-button-text {
  display: flex;
  justify-content: end;
}

.agent-header-button-design {
  border-radius: 4px;
  background: MainStyle.$theme-background-third;
  border: none;
  color: MainStyle.$theme-font-primary;
}

.agent-header-button-complete-design {
  border-radius: 4px;
  background: MainStyle.$theme-btn;
  border: none;
  color: MainStyle.$theme-font-primary;
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.center-wrapper-add {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 38vh;
}

.save-button-token {
  background: MainStyle.$theme-btn !important;
}

.token-button .ant-input {
  background: transparent !important;
  border: none !important;
}

.agent-add {
  display: flex;
  justify-content: center;
}

.token-agent {
  margin-top: 4rem;
}

.icon-create {
  color: MainStyle.$theme-font-primary !important;
  font-size: 24px !important;
  padding-right: 6px;
  padding-top: 4px;
}

.create-modal .ant-modal-content {
  background-color: MainStyle.$theme-background-secondary !important;
  /* background: rgba(0, 0, 0, 0.31) !important; */
}

.create-modal .ant-card {
  background-color: MainStyle.$theme-background-secondary !important;
  border: none !important;
}

/* background: rgba(0, 0, 0, 0.31) !important; */
.create-card-text {
  background-color: MainStyle.$theme-background-secondary !important;
}

.create-card-text .ant-card {
  background: MainStyle.$theme-background-secondary !important;
  border: none;
  color: MainStyle.$theme-font-primary !important;
  width: 300px;
  height: 200px;
  text-align: center !important;
}

.create-token-button {
  width: 100% !important;
  background: rgba(255, 255, 255, 0.06) !important;
  border: none;
  color: MainStyle.$theme-font-primary !important;
}

.token-header {
  text-align: center !important;
  color: MainStyle.$theme-font-primary;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.create-btn {
  border-radius: 4px;
  background: MainStyle.$theme-btn !important;
  color: MainStyle.$theme-font-primary !important;
  font-size: 18px;
  border: none;
  padding: 2px 2px;
  width: 10rem;
  display: flex;
  justify-content: center;
}

.token-button {
  padding: 5px 5px;
}

.token-card-title-text {
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */

  font-size: 23px;
  font-style: normal;
  font-weight: 600;
}

.token-card-title {
  display: flex;
  justify-content: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
}

.token-card-main .ant-btn {
  padding: 0px 7px !important;
}

.token-success {
  color: MainStyle.$theme-font-primary !important;
  background-color: MainStyle.$theme-btn;
  border-radius: 6px;
  border: none !important;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.token-first {
  border-radius: 6px;
  color: MainStyle.$theme-font-primary !important;
  width: 100% !important;
  font-size: 18px;
}

.token-card-title .ant-avatar.ant-avatar-icon > .anticon {
  vertical-align: 0.2em !important;
}

.agent-header-button-design:hover {
  border-color: #848484 !important;
  color: MainStyle.$theme-font-primary !important;
}

.agent-header-button-complete-design:hover {
  color: MainStyle.$theme-font-primary !important;
}

.credit-button {
  padding: 5px;
  justify-content: center;
  text-wrap: nowrap;
}

.credit-image {
  padding-left: 2px;
}

.credit-text {
  color: MainStyle.$theme-btn !important;
}

.token-credits-text {
  text-align: center;
}

.agent-text-select {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  padding-top: 1.4rem !important;
}

.agent-text-select.ant-select .ant-select-arrow {
  color: #848484 !important;
  padding: 5px;
  padding-top: 15px;
}

.token-credits-text {
  cursor: pointer;
}

.log-body {
  width: 100% !important;
}

.text-body-log {
  background-color: transparent !important;
  color: MainStyle.$theme-font-primary !important;
  border: 1px solid MainStyle.$theme-font-primary !important;
  border-radius: 4px;
  padding: 5px;
}

.text-right-text {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.text-left-text {
  display: flex;
  justify-content: start;
  padding: 5px;
  width: 100%;
}

/* .ant-select .ant-select-arrow {
    color: #848484 !important;
} */

.agent-text-select.ant-select .ant-select-arrow:hover {
  border: #848484 !important;
}

.agent-text-select .ant-select {
  width: 100% !important;
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .pro-body {
    background-color: MainStyle.$theme-background-secondary !important;
    color: MainStyle.$theme-font-primary !important;
    margin-top: 0rem !important;
  }

  .pro-sub-body {
    color: MainStyle.$theme-font-primary !important;
    margin: 1rem 2rem;
    min-height: 90vh !important;
  }

  .token-cal .ant-picker {
    background-color: MainStyle.$theme-background-third !important;
    color: MainStyle.$theme-font-primary !important;
    width: 100%;
  }
  .token-title-mobile {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .pro-body {
    background-color: MainStyle.$theme-background-secondary !important;
    color: MainStyle.$theme-font-primary !important;
    margin-top: 0rem !important;
  }

  .pro-sub-body {
    color: MainStyle.$theme-font-primary !important;
    margin: 1rem 2rem;
    min-height: 90vh !important;
  }

  .token-cal .ant-picker {
    background-color: MainStyle.$theme-background-third !important;
    color: MainStyle.$theme-font-primary !important;
    width: 100%;
  }
  .token-title-mobile {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .pro-body {
    background-color: MainStyle.$theme-background-secondary !important;
    color: MainStyle.$theme-font-primary !important;
    margin-top: 0rem !important;
  }

  .pro-sub-body {
    color: MainStyle.$theme-font-primary !important;
    margin: 1rem 2rem;
    min-height: 90vh !important;
  }

  .token-cal .ant-picker {
    background-color: MainStyle.$theme-background-third !important;
    color: MainStyle.$theme-font-primary !important;
    width: 100%;
  }
  .token-title-mobile {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 320px) {
  .pro-body {
    background-color: MainStyle.$theme-background-secondary !important;
    color: MainStyle.$theme-font-primary !important;
    margin-top: 0rem !important;
  }

  .pro-sub-body {
    color: MainStyle.$theme-font-primary !important;
    margin: 1rem 2rem;
    min-height: 90vh !important;
  }

  .token-cal .ant-picker {
    background-color: MainStyle.$theme-background-third !important;
    color: MainStyle.$theme-font-primary !important;
    width: 100%;
  }
  .token-title-mobile {
    display: flex;
    justify-content: center;
  }
}
