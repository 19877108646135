@use "../../MainStyle";

.bredcrumb-chat-home-icon {
  font-size: 15px;
  cursor: pointer;
  color: MainStyle.$theme-font-primary;
  margin-top: -4px !important;
}
.bredcrumb-promp-arrow {
  color: MainStyle.$theme-font-primary;
  font-size: 15px;
}
.breadcrumb-title {
  font-size: 15px;
}
