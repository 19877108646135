// variable setting
// colors
$color-white: #ffff;
$color-black: #00000000;
$text-color-grey: rgba(35, 35, 35, 0.7);
$red-linear-gradient: linear-gradient(
  98.06deg,
  #f33a56 -6.61%,
  #6e039f 152.99%
);
$linkedin-color: #0e76a8;
$color-blue: #1c3892;
$bg-light-grey: #f8f8f8;
$separator-line: #e3edf4;

$color-grey: #333333;
$color-header-grey: #232323;

$color-carousel-header: #232323;

$font-color-black: #232323;
$error-color: #de3161;
$success-color: #0be059;

$color-light: #ebeffb;
$color-secondary: rgba(35, 35, 35, 0.7);
// colors

// --font-weight --
$font-weight400: 400;
$font-weight500: 500;
$font-weight600: 600;
$font-weight700: 700;
// --font-weight --

$accent-color: red;
//--font-family --

// $font-family-san-serif: san-serif;

//--font-family --

//--font-size --
$font-size20: 1.25rem;
$font-size10: 1rem;
$font-size12: 0.75rem;
$font-size14: 0.875rem;
$font-size15: 0.9375rem;
$font-size095rem: 0.95rem;

//--font-size --

$cursor-pointer: pointer;

p {
  margin-top: 0;
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
$small: 300px;
$medium: 900px;

.red-gradient {
  color: $red-linear-gradient;
  background: $red-linear-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.button-primary {
  background: $color-blue;
  // padding: 0.7rem 2rem;
  font-weight: $font-weight600;
  border-radius: 4px;
  border: 2px solid $color-blue !important;
  font-size: 0.875rem;
}

.background {
  background: $bg-light-grey;
  height: 100vh;
}

.button-secondary {
  color: $color-blue;
  background: white;
  padding: 0.7rem 2rem;
  border: 2px solid $color-blue;
  font-weight: $font-weight500;
  border-radius: 4px;
  font-size: 0.875rem;
}

.button-secondary:hover {
  color: $color-blue;
  padding: 0.7rem 2rem;
  border: 1px solid $color-blue;
  font-weight: $font-weight500;
  border-radius: 4px;
}

.button-tertiary {
  border: 2px solid #f33a56;
  // color: white;
  background: linear-gradient(93.58deg, #f33a56 7.76%, #6e039f 267.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.7rem 2rem;
  font-weight: $font-weight500;
  border-radius: 4px;
}

.button-cancel {
  background: linear-gradient(93.58deg, #f33a56 7.76%, #6e039f 267.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.7rem 2rem;
  font-weight: $font-weight500;
  border-radius: 4px;
  border: 2px solid #f33a56;
  font-size: 0.875rem;
}

.button-cancel:hover{
  text-decoration: none;
}
// .button-tertiary:hover {
//   border: 2px solid #f33a56;
//   background: linear-gradient(93.58deg, #f33a56 7.76%, #6e039f 267.27%);
//   color: white !important;
// }
.btn:disabled {
  background: #919191 !important;
  border: none !important;
}
.text-black {
  color: $color-black;
}
.w-30 {
  width: 30%;
}
.w-65 {
  width: 68%;
}
.margin-right1 {
  margin-right: 0.5rem;
}
.css-6j8wv5-Input {
  margin: 0px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.error-text {
  font-size: 11px;
  color: red;
  display: none;
  margin: 5px 5px;
  font-weight: 700;
}

.ant-table {
    font-family: Arial, sans-serif;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f0f2f5;
    color: #000;
    font-weight: bold;
  }
  
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #f0f5ff;
  }
  
  .ant-table-tbody > tr.ant-table-row:hover > td .iconify-icon {
    color: #52c41a; /* Change the color for the icon on hover */
  }
  