.mobile-launch {
    background-image: url("../../assets/mobilelogobackground.svg");
    /* background-repeat: no-repeat;
    background-size: cover; */
    margin: 5%;
    display: flex;
    justify-content: center;
    
}
.mobile-header{
    padding: 4px;
}
.mobile-header-logo{
    display: flex;
    justify-content: center;
    top:4rem;
    left:2rem;
  
}
.mobile-header-logo-img{
    width: 12rem;
}
