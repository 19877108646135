@use "../../MainStyle";

.launch-logo {
  width: 33% !important;
}
.launch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.launch-container-text {
  padding: 35px;
  /* margin-left: 57px; */
  /* margin-left: 5%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

/* .launch-qus-search{
    width: 100%;
} */
.launch-qus-search .ql-container.ql-snow {
  border-radius: 10px !important;
  border: 1px solid #de2bae !important;
  width: 40rem;
  margin: 0px;
  padding: 0.6rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 400;
}
.suggestions-list-launch {
  margin-left: 7rem;
  background-color: MainStyle.$theme-background-secondary;
  color: MainStyle.$theme-font-primary;
  width: 300px;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 200px;
  overflow-y: auto;
}
.suggestions-list-launch::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.suggestions-list-launch::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
    background-color: #D9E9FF; */
  background-color: MainStyle.$theme-background-secondary;
  border-radius: 0.5rem;
}
.suggestions-list-launch::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid MainStyle.$theme-background-secondary;
}

.suggestions-list-launchquery {
  overflow-y: auto;
  border: 1px solid #424352;
  background-color: MainStyle.$theme-background-secondary;
  color: MainStyle.$theme-font-primary;
  border-radius: 4px;
  width: 39rem;
  margin-left: 7.2rem;
}
.suggestions-list-item-qus {
  padding: 8px 0;
  border-bottom: 1px solid #848484;
  padding-left: 1rem;
  cursor: pointer;
}
.launch-container-body {
  position: relative;
}
.query-list {
  position: absolute;
  top: 5.2rem;
  left: 19rem;
}
.query-list ul {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.launch-qus-search .ql-editor::before {
  color: rgba(255, 255, 255, 0.17);
  font-family: "Poppins" !important;
}
@media (min-width: 2561px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 76rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 24rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 75.5rem;
    margin-left: 23.2rem;
  }
}
@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 76rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 24rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 75.5rem;
    margin-left: 23.2rem;
  }
}
@media only screen and (max-width: 2304px) and (min-width: 2161px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 76rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 16rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 75.5rem;
    margin-left: 15.2rem;
  }
}
@media only screen and (max-width: 2160px) and (min-width: 1922px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 66rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 16rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    background-color: MainStyle.$theme-background-secondary;
    border: 1px solid #424352;
    border-radius: 4px;
    color: MainStyle.$theme-font-primary;
    margin-left: 12.2rem;
    overflow-y: auto;
    width: 65.5rem;
  }
}
@media only screen and (max-width: 1921px) and (min-width: 1681px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 61rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 16rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    background-color: MainStyle.$theme-background-secondary;
    border: 1px solid #424352;
    border-radius: 4px;
    color: MainStyle.$theme-font-primary;
    margin-left: 23.2rem;
    overflow-y: auto;
    width: 75.5rem;
  }
}
@media only screen and (max-width: 1680px) and (min-width: 1601px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 53rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 8rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 52.5rem;
    margin-left: 7.2rem;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1471px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 47rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 8rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 45.5rem;
    margin-left: 8.2rem;
  }
}
@media only screen and (max-width: 1470px) and (min-width: 1441px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 47rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 3rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 46.4rem;
    margin-left: 3.5rem;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 47rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 3rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 45.5rem;
    margin-left: 3.2rem;
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 40rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 4rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 45.5rem;
    margin-left: 4.2rem;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1081px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 41rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: 1rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 39.5rem;
    margin-left: 1.2rem;
  }
}
@media only screen and (max-width: 1080px) and (min-width: 1025px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 41rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: -5rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 39.5rem;
    margin-left: -5rem;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 41rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: -7rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 40.2rem;
    margin-left: -7rem;
  }
}
@media only screen and (max-width: 768px) and (min-width: 427px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 41rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: -15rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 40.2rem;
    margin-left: -15rem;
  }
}
@media only screen and (max-width: 426px) and (min-width: 377px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 24rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: -18rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 24rem;
    margin-left: -17.8rem;
  }
}
@media only screen and (max-width: 376px) and (min-width: 321px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 24rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: -18rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 24rem;
    margin-left: -17.8rem;
  }
}
@media (max-width: 320px) {
  .launch-qus-search .ql-container.ql-snow {
    border-radius: 10px !important;
    border: 1px solid #de2bae !important;
    width: 24rem;
    margin: 0px;
    padding: 0.6rem;
    height: 3rem;
    font-size: 18px;
    font-weight: 400;
  }
  .suggestions-list-launch {
    margin-left: -18rem;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    width: 300px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: auto;
  }
  .suggestions-list-launchquery {
    overflow-y: auto;
    border: 1px solid #424352;
    background-color: MainStyle.$theme-background-secondary;
    color: MainStyle.$theme-font-primary;
    border-radius: 4px;
    width: 24rem;
    margin-left: -17.8rem;
  }
}
