@use "../../MainStyle";
.filterResultCard:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
  box-shadow: 0px 25px 62px rgba(28, 56, 146, 0.13);
}

.filterResultCard {
  width: 100%;
  /* height: 200px; */
  color: MainStyle.$theme-font-primary;
  /* background: MainStyle.$theme-background-secondary;
  border-radius: 8px; */
  border: 1px solid rgba(0, 0, 0, 0.075) !important;
  position: relative;
}

.filterResultCard-bg {
  background: MainStyle.$theme-background-secondary;
  border-radius: 18px;
  margin-top: 3rem;
  height: 15rem;
}

.filter-avatar-img {
  position: absolute;
  left: 40%;
  top: 0%;
}

.avatar-list-token-body {
  text-align: right;
  margin: 0.5rem 0.8rem;
}

.avatar-body-height {
  height: 6.5rem;
}

.avatar-list-token {
  font-size: 19px;
  font-weight: 300;
  line-height: normal;
}

.companyLogoImage {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}

.labelStyle {
  font-weight: 400;
  font-size: 11px !important;
  line-height: 16.07px;
  color: rgba(255, 255, 255, 0.49);
  text-align: justify;
  margin: 0 1.4rem;
}

.labelStyle-title {
  font-weight: 400;
  font-size: 19px !important;
  text-align: center;
}

/* .avatar-btn {
  background-color: #213a9c;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: MainStyle.$theme-font-primaryfff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 6px 24px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.avatar-btn:hover,
.avatar-btn:focus {
  background-color: #5974e0;
} */

.button-primary {
  background: MainStyle.$theme-btn !important;
  padding: 0.4rem 2rem;
  font-weight: 500;
  border-radius: 9px;
  border: none !important;
}

.button-primary:hover {
  background: MainStyle.$theme-btn !important;
  /* color: #db2aac !important; */
  color: MainStyle.$theme-font-primary !important;
  transition: ease 0.2s;
}

.text-right {
  text-align: right !important;
}

.cams-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}

.cams-tabs .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 3rem;
}

.ant-tabs-nav-list {
  border: 0;
}

.ant-tabs-tab {
  margin: 0px !important;
  background: transparent !important;
  font-size: 18px;
  font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background: transparent !important;
}

/* .ant-tabs-tab.ant-tabs-tab-active::after {
  content: "";
  display: block;
  height: 3px;
  width: 20px;
  background-color: #1c3892;
  transition: all 0.5s;
} */
.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: MainStyle.$theme-btn !important;
}

.cams-tabs .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 3rem;
  font-weight: 400;
  font-size: 15px;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: MainStyle.$theme-btn !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}

.library-head-logo {
  width: 3%;
}

.aivolvex-avatar-title {
  font-size: 20px;
}

.avator-list .ant-card-body {
}

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .library-desktop {
    display: none;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .library-desktop {
    display: none;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .library-desktop {
    display: none;
  }

  .library-mobile {
    display: block !important;
    color: aliceblue !important;
  }
}

@media (max-width: 320px) {
  .library-desktop {
    display: none;
  }
}
