@use "../../MainStyle";

.mobile-banner {
  display: none !important;
}

/* @media only screen and (max-width: 768px) and (min-width: 427px) {
    .mobile-col img {
        width: 100% !important;

    }
} */

@media (max-width: 320px) {
  .banner-bg {
    background-image: url("../../assets/mobilebackbanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100%; */
    width: 320px;
    /* height: 700px !important; */
    min-height: 100vh;
  }

  .mobile-col img {
    width: 100%;
  }

  .shadow-img {
    /* width: 320px !important; */
    /* width: 375px !important; */
    /* width: 631px; */
    /* height: 306px */
    /* top: -162px !important;
        left: -117.39px !important;
        transform: rotate(-16.05deg) !important; */
  }

  .mobile-banner {
    display: block !important;
  }

  .mobile-banner-logo {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .mobile-logo {
    margin-top: -33%;
  }

  .mobile-banner-logo-text {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
  }

  .mobile-avatar {
    top: -20px !important;
    margin-top: -31%;
  }

  .separator-line {
    margin-top: 0rem !important;
  }

  .mobile-view-avatar {
    /* margin-top: -10%; */
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .mobile-banner-text {
    background: var(
      --C,
      linear-gradient(
        93deg,
        #eb77e7 27.5%,
        MainStyle.$theme-btn 46.64%,
        #9340e8 68.78%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
  }

  .mobile-banner-text-normal {
    color: MainStyle.$theme-font-primary;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 10px; */
    line-height: 124%;
  }

  .signin-btn {
    border-radius: 4px;
    // background: #de2bae;
    background-color: MainStyle.$theme-btn;
    border: none;
    width: 271px;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .forgot-password {
    display: flex;
    justify-content: flex-end;
    color: MainStyle.$theme-font-primary;
    padding: 5px;
  }

  .signup-btn {
    border-radius: 4px;
    background: transparent;
    border: 1px solid MainStyle.$theme-btn;
    color: MainStyle.$theme-btn;
    width: 271px;
    cursor: pointer;
  }

  .signin-form {
    display: flex;
    justify-content: center;
    font-size: 17px;
  }

  .signin-form.ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-form-item {
    margin-bottom: 14px !important;
  }

  .signin-form-input {
    width: 271px !important;
    background-color: #39114275 !important;
    border: none !important;
    color:MainStyle.$theme-font-primary;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background-color: #39114275 !important;
    border: none !important;
  }

  .anticon[tabindex] {
    cursor: pointer;
    color: MainStyle.$theme-font-primary !important;
  }

  /* .signin-social-btn {
        background: #24264c !important;
        border: none;
    } */
  .signin-form .ant-input:hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signin-form.ant-input:hover {
    /* border-color: #4096ff; */
    /* border-inline-end-width: 1px !important; */
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signup-here {
    color: MainStyle.$theme-btn !important;
    cursor: pointer !important;
  }

  .signup-here-prefix {
    color: MainStyle.$theme-font-primary !important;
  }

  .azue-sigin-btn {
    width: 271px;
    // background: #24264c !important;
    background-color: MainStyle.$theme-background-third;
    border: none;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .avatar-logo {
    margin-top: 40px;
  }

  .term-text {
    font-size: 8.5px !important;
    font-weight: 400;
    margin-bottom: 7px !important;
  }

  .avatar-user {
    margin-bottom: 17px !important;
  }

  /* .ant-checkbox-input {
        background: #000 !important;
    } */

  .term-condition {
    padding: 5px;
    /* margin-bottom: 7px !important; */
  }

  /* 
    .text-checkbox {
        background: #000 !important;
    }

    .text-checkbox input[type="checkbox"] {
        border-color: black !important;
        background-color: black !important;
    } */

  .signup-footer {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .banner-bg {
    background-image: url("../../assets/mobilebackbanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100%; */
    width: 376px;
    /* height: 700px !important; */
    min-height: 100vh;
  }

  .mobile-col img {
    width: 100%;
  }

  .mobile-logo {
    margin-top: -30%;
  }

  .shadow-img {
    /* width: 320px !important; */
    /* width: 375px !important; */
    /* width: 631px; */
    /* height: 306px */
    /* top: -162px !important;
        left: -117.39px !important;
        transform: rotate(-16.05deg) !important; */
  }

  .mobile-banner {
    display: block !important;
  }

  .mobile-banner-logo {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .mobile-banner-logo-text {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
  }

  .mobile-avatar {
    top: -20px !important;
    margin-top: -31%;
  }

  .signin-form.ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-form-item {
    margin-bottom: 14px !important;
  }

  .separator-line {
    margin-top: 0rem !important;
  }

  .mobile-view-avatar {
    /* margin-top: -41%; */
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none !important;
    // background: #09091c !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .mobile-banner-text {
    background: var(
      --C,
      linear-gradient(
        93deg,
        #eb77e7 27.5%,
        MainStyle.$theme-btn 46.64%,
        #9340e8 68.78%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
  }

  .mobile-banner-text-normal {
    color: MainStyle.$theme-font-primary;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 10px; */
    line-height: 124%;
  }

  .signin-btn {
    border-radius: 4px;
    // background: #de2bae;
    background-color: MainStyle.$theme-btn;
    border: none;
    width: 271px;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .forgot-password {
    display: flex;
    justify-content: flex-end;
    color: MainStyle.$theme-font-primary;
    padding: 5px;
  }

  .signup-btn {
    border-radius: 4px;
    background: transparent;
    border: 1px solid MainStyle.$theme-btn;
    color: MainStyle.$theme-btn;
    width: 271px;
    cursor: pointer;
  }

  .signin-form {
    display: flex;
    justify-content: center;
    font-size: 17px;
  }

  .signin-form-input {
    width: 271px !important;
    // background-color: #39114275 !important;
    border: none !important;
    // color:#fff !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background-color: #39114275 !important;
    border: none !important;
  }

  .anticon[tabindex] {
    cursor: pointer;
    color: MainStyle.$theme-font-primary !important;
  }

  /* .signin-social-btn {
        background: #24264c !important;
        border: none;
    } */
  .signin-form .ant-input:hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signin-form.ant-input:hover {
    /* border-color: #4096ff; */
    /* border-inline-end-width: 1px !important; */
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signup-here {
    color:MainStyle.$theme-btn!important;
    cursor: pointer !important;
  }

  .signup-here-prefix {
    color: MainStyle.$theme-font-primary !important;
  }

  .azue-sigin-btn {
    width: 271px;
    // background: #24264c !important;
    background-color: MainStyle.$theme-background-third;
    border: none;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .avatar-logo {
    margin-top: 40px;
  }

  .term-text {
    font-size: 8.5px !important;
    font-weight: 400;
    margin-bottom: 7px !important;
  }

  .avatar-user {
    margin-bottom: 17px !important;
  }

  /* .ant-checkbox-input {
        background: #000 !important;
    } */

  .term-condition {
    padding: 5px;
    /* margin-bottom: 7px !important; */
  }

  /* 
    .text-checkbox {
        background: #000 !important;
    }

    .text-checkbox input[type="checkbox"] {
        border-color: black !important;
        background-color: black !important;
    } */

  .signup-footer {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .banner-bg {
    background-image: url("../../assets/mobilebackbanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* width: 100%; */
    width: 426px;
    /* height: 700px !important; */
    min-height: 100vh;
  }

  .mobile-col img {
    /* width: 100% !important; */
    width: 26.6rem !important;
  }

  .shadow-img {
    /* width: 320px !important; */
    /* width: 375px !important; */
    /* width: 631px; */
    /* height: 306px */
    /* top: -162px !important;
        left: -117.39px !important;
        transform: rotate(-16.05deg) !important; */
  }

  .mobile-banner {
    display: block !important;
  }

  .mobile-banner-logo {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .mobile-banner-logo-text {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
  }

  .mobile-avatar {
    top: -20px !important;
    margin-top: -31%;
  }

  .mobile-logo {
    margin-top: -35%;
  }

  .separator-line {
    margin-top: 0rem !important;
  }

  .mobile-view-avatar {
    /* margin-top: -41%; */
  }

  .signin-form.ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .mobile-banner-text {
    background: var(
      --C,
      linear-gradient(
        93deg,
        #eb77e7 27.5%,
        MainStyle.$theme-btn 46.64%,
        #9340e8 68.78%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
  }

  .mobile-banner-text-normal {
    color: MainStyle.$theme-font-primary;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 10px; */
    line-height: 124%;
  }

  .signin-btn {
    border-radius: 4px;
    // background: #de2bae;
    background-color: MainStyle.$theme-btn;
    border: none;
    width: 271px;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .forgot-password {
    display: flex;
    justify-content: flex-end;
    color: MainStyle.$theme-font-primary;
    padding: 5px;
  }

  .signup-btn {
    border-radius: 4px;
    background: transparent;
    border: 1px solid MainStyle.$theme-btn;
    color: MainStyle.$theme-btn;
    width: 271px;
    cursor: pointer;
  }

  .signin-form {
    display: flex;
    justify-content: center;
    font-size: 17px;
  }

  .signin-form-input {
    width: 271px !important;
    // background-color: #39114275 !important;
    border: none !important;
    // color:#fff !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background-color: #39114275 !important;
    border: none !important;
  }

  .anticon[tabindex] {
    cursor: pointer;
    color: MainStyle.$theme-font-primary !important;
  }

  /* .signin-social-btn {
        background: #24264c !important;
        border: none;
    } */
  .signin-form .ant-input:hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signin-form.ant-input:hover {
    /* border-color: #4096ff; */
    /* border-inline-end-width: 1px !important; */
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signup-here {
    color: MainStyle.$theme-btn !important;
    cursor: pointer !important;
  }

  .signup-here-prefix {
    color: MainStyle.$theme-font-primary !important;
  }

  .azue-sigin-btn {
    width: 271px;
    background: #24264c !important;
    background-color: MainStyle.$theme-background-third;
    border: none;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .avatar-logo {
    margin-top: 40px;
  }

  .term-text {
    font-size: 8.5px !important;
    font-weight: 400;
    margin-bottom: 7px !important;
  }

  .avatar-user {
    margin-bottom: 17px !important;
  }

  /* .ant-checkbox-input {
        background: #000 !important;
    } */

  .term-condition {
    padding: 5px;
    /* margin-bottom: 7px !important; */
  }

  /* 
    .text-checkbox {
        background: #000 !important;
    }

    .text-checkbox input[type="checkbox"] {
        border-color: black !important;
        background-color: black !important;
    } */

  .signup-footer {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .mobile-banner {
    display: block !important;
  }

  .main-container {
    display: none !important;
  }

  .tab-head {
    margin-top: 25px;
  }

  // .separator-line span {
  //   background-color:linear-gradient(90deg,MainStyle.$theme-background-primary 0%,MainStyle.$theme-background-primary 100% ) !important; 

  // }

  .banner-bg {
    background-image: url("../../assets/mobilebackbanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 768px;
    /* height: 990px !important; */
    min-height: 100vh;
  }

  .mobile-col img {
    width: 768px !important;
    height: 300px !important;
  }

  .tab-logo {
    padding: 23px !important;
  }

  .tab-avatar {
    /* margin-top: -173px !important; */
  }

  .signin-form.ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-form-item {
    margin-bottom: 14px !important;
  }

  .shadow-img {
    /* width: 320px !important; */
    /* width: 375px !important; */
    /* width: 631px; */
    /* height: 306px */
    /* top: -162px !important;
            left: -117.39px !important;
            transform: rotate(-16.05deg) !important; */
  }

  .mobile-banner {
    display: block !important;
  }

  .mobile-banner-logo {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .mobile-banner-logo-text {
    display: flex;
    justify-content: center;
    /* padding: 14px; */
  }

  .mobile-avatar {
    top: -20px !important;
    margin-top: -31%;
  }

  .separator-line {
    margin-top: 0rem !important;
  }

  .mobile-view-avatar {
    /* margin-top: -41%; */
  }

  .mobile-logo {
    margin-top: -20%;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .mobile-banner-text {
    background: var(
      --C,
      linear-gradient(
        93deg,
        #eb77e7 27.5%,
        MainStyle.$theme-btn 46.64%,
        #9340e8 68.78%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 124%;
  }

  .mobile-banner-text-normal {
    color: MainStyle.$theme-font-primary;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 10px; */
    line-height: 124%;
  }

  .signin-btn {
    border-radius: 4px;
    // background: #de2bae;
    background-color: MainStyle.$theme-btn;
    border: none;
    width: 271px;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .forgot-password {
    display: flex;
    justify-content: flex-end;
    color: MainStyle.$theme-font-primary;
    padding: 5px;
  }

  .signup-btn {
    border-radius: 4px;
    background: transparent;
    border: 1px solid MainStyle.$theme-btn;
    color: MainStyle.$theme-btn;
    width: 271px;
    cursor: pointer;
  }

  .signin-form {
    display: flex;
    justify-content: center;
    font-size: 17px;
  }

  .signin-form-input {
    width: 271px !important;
    // background-color: #39114275 !important;
    border: none !important;
    // color:#fff !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    // background-color: #39114275 !important;
    border: none !important;
  }

  .anticon[tabindex] {
    cursor: pointer;
    color: MainStyle.$theme-font-primary !important;
  }

  /* .signin-social-btn {
            background: #24264c !important;
            border: none;
        } */
  .signin-form .ant-input:hover {
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signin-form.ant-input:hover {
    /* border-color: #4096ff; */
    /* border-inline-end-width: 1px !important; */
    border: none !important;
    // background: #09091c !important;
    // box-shadow: 0px 0px 9px 0px #de2bae82 !important;
    box-shadow: 0px 0px 9px 0px  MainStyle.$theme-btn !important;
  }

  .signup-here {
    // color: #de2bae !important;
    color:MainStyle.$theme-btn !important;
    cursor: pointer !important;
  }

  .signup-here-prefix {
    color: MainStyle.$theme-font-primary !important;
  }

  .azue-sigin-btn {
    width: 271px;
    // background: #24264c !important;
    background-color: MainStyle.$theme-background-third;
    border: none;
    color: MainStyle.$theme-font-primary;
    cursor: pointer;
  }

  .avatar-logo {
    margin-top: 40px;
  }

  .term-text {
    font-size: 8.5px !important;
    font-weight: 400;
    margin-bottom: 7px !important;
  }

  .avatar-user {
    margin-bottom: 17px !important;
  }

  /* .ant-checkbox-input {
        background: #000 !important;
    } */

  .term-condition {
    padding: 5px;
    /* margin-bottom: 7px !important; */
  }

  /* .text-checkbox {
        background: #000 !important;
    } */
  /* 
    .text-checkbox input[type="checkbox"] {
        border-color: black !important;
        background-color: black !important;
    } */

  .signup-footer {
    padding: 5px !important;
  }
}
